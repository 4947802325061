import React, {useContext, useEffect, useRef, useState} from "react"
import {endpoint} from "../../variables"
import {useNavigate, useLocation} from "react-router-dom"
import {Axios} from "../../Axios"
import {createOptionsList, getUrlParams} from "../../utils/_helpers"
import Loader from "../_shared/Loader/Loader"
import Button from "../_shared/Form/Button/Button"
import {CustomerIF, SelectOptionIF, WorksheetIF} from "../../../types"
import {useTranslation} from "react-i18next"
import {handleError} from "../../shared/helpers"
import {getCustomers} from "../../utils/Getters/getCustomers"
import {getProducts} from "../../utils/Getters/getProducts"
import worksheets, {worksheetsPath} from "../Worksheets/Worksheets"
import CreatableSelect from "react-select/creatable"
import {Context} from "../../contexts/Context";

export const worksheetPath = "/worksheet/"

const Worksheet = () => {
    const {t} = useTranslation()
    const [worksheet, setWorksheet] = useState<WorksheetIF>({
        id: 0
    })
    const {notification} = useContext(Context)
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber", "customerId", "worksheetPage", "worksheetId"])
    const page = parseInt(path["pageNumber"]) || 1
    const customerId = parseInt(path["customerId"])
    const worksheetId = parseInt(path["worksheetId"])
    const worksheetPage = parseInt(path["worksheetPage"])
    const [loader, setLoader] = useState(true)
    const customerRef = useRef<any | null>(null)
    const productRef = useRef<any | null>(null)
    const quantityRef = useRef<HTMLInputElement>(null)
    const [customers, setCustomers] = useState<Array<SelectOptionIF>>([])
    const [products, setProducts] = useState<Array<SelectOptionIF>>([])
    const [customer, setCustomer] = useState<string | null>(null)
    const placeholder = {
        placeholder: t("products.form.placeholder")
    }
    const [selectedProduct, setSelectedProduct] = useState<SelectOptionIF | null>(null)
    const [requestData, setRequestData] = useState({
        customer: customerId || 0,
        product: 0,
        quantity: 1
    })

    // get Customers and Products on first load
    useEffect(() => {
        getCustomers({
            callback: (data: Array<CustomerIF>) => {
                setCustomers(createOptionsList(data))
            }
        })

        getProducts({
            callback: ({resource}: any) => {
                setProducts(createOptionsList(resource, "id"))
            }
        })
    }, [customerId])

    // set page title to have customers name
    useEffect(() => {
        if (!customers.length || !customerId) {
            return
        }

        setCustomer(customers.filter(item => parseInt(item.value) === customerId)[0].label)
    }, [customers, customerId])

    // set selected Product once we have all the data
    useEffect(() => {
        if (!products.length || !worksheets.length || !worksheetId) {
            return
        }

        const currentProduct = products.filter(item => parseInt(item.value) === worksheet.product_id)[0]

        setRequestData({
            ...requestData,
            product: currentProduct ? parseInt(currentProduct.value) : 0,
            quantity: worksheet.quantity || 0
        })
        setSelectedProduct(currentProduct)
    }, [products, worksheets])

    // fetch Worksheet details
    useEffect(() => {
        if (!worksheetId) {
            setWorksheet({
                id: 0
            })

            setLoader(false)

            return
        }

        Axios.get(`${endpoint}${worksheetPath}${worksheetId ? `${worksheetId}/` : ""}`)
            .then(({data}) => {
                const {resource} = data

                setWorksheet(resource)
                setLoader(false)
            })
            .catch(error => handleError(error))
    }, [worksheetId])

    const updateItem = () => {
        Axios.put(`${endpoint}${worksheetPath}${worksheetId}/`, {
            ...requestData,
            id: worksheetId
        })
            .catch(error => handleError(error))
    }

    const addItem = () => {
        Axios.post(`${endpoint}${worksheetPath}`, requestData)
            .then(({data}) => {
                const {success} = data

                if (!success) {
                    handleError(`No luck saving worksheet ryan, mate!`)
                }

                navigate(`${worksheetsPath}1/${requestData.customer}/${worksheetPage}`)
            })
            .catch(error => handleError(error))
    }

    const doSave = () => {
        if (worksheet.id > 0) {
            updateItem()
        } else {
            addItem()
        }
    }

    const onChange = (value: string | undefined, field: string) => {
        setRequestData({
            ...requestData,
            [field]: parseFloat(value as string)
        })
    }

    useEffect(() => {
        if (worksheet.id > 0) {
            updateItem()
        }
    }, [requestData])

    if (worksheetId && !selectedProduct) {
        return null
    }

    return (
        loader ?
            <Loader/>
            :
            <>
                <section className="component customer">
                    <header>
                        <h1>
                            {(worksheet && worksheet.id) || worksheetId ? `${t("worksheets.singular")} #${worksheetId}` : `${t("new")} ${t("worksheets.singular")}`}{customer && ` - ${customer}`}
                        </h1>

                        <div className="actions">
                            <Button additionalClassName="primary large"
                                    text={t("button.save")}
                                    onClick={() => {
                                        doSave()
                                        notification?.update({
                                            show: true
                                        })
                                    }}></Button>
                            <Button additionalClassName="secondary large"
                                    text={t("button.goBack")}
                                    onClick={() => navigate(`${worksheetsPath}${page}/${customerId ? `${customerId}/` : ""}${worksheetPage ? `${worksheetPage}/` : ""}`)}
                            ></Button>
                        </div>
                    </header>

                    <form className="general-form product">
                        {!customerId &&
                            <div className="field">
                                <label htmlFor="customer-field">{t("orders.form.customer")}</label>
                                <CreatableSelect
                                    className="select"
                                    classNamePrefix="select"
                                    defaultValue={customers.filter(item => parseInt(item.value) === customerId)[0]}
                                    name="customer"
                                    id="customer-field"
                                    options={customers}
                                    ref={customerRef}
                                    isValidNewOption={() => false}
                                    onChange={(item) => onChange(item?.value, "customer")}
                                    placeholder={t("customers.form.placeholder")}
                                />
                            </div>
                        }

                        <div className="field">
                            <label>{t("worksheets.form.product")}</label>

                            <CreatableSelect
                                className="select"
                                classNamePrefix="select"
                                defaultValue={selectedProduct}
                                name="product-select"
                                options={products}
                                ref={productRef}
                                isValidNewOption={() => false}
                                onChange={(item) => onChange(item?.value, "product")}
                                {...placeholder}
                            />
                        </div>

                        <div className="field">
                            <label>{t("worksheets.form.quantity")}</label>
                            <input
                                name="quantity"
                                type="number"
                                ref={quantityRef}
                                defaultValue={worksheet.quantity || 1}
                                onChange={(event) => onChange(event.target?.value, "quantity")}
                            />
                        </div>

                        <input type="submit" className="hidden"/>
                    </form>
                </section>
            </>
    )
}

export default Worksheet
