export const isEmpty = (obj: object | null) => {
    if (obj === null) {
        return false
    }

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false
        }
    }

    return true
}

export const formattedDate = (input: Date) => {
    const date = new Date(input)

    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
}

export const formatDate = (timestamp?: string, extra?: number) => {
    if (!timestamp) {
        return
    }

    const date = new Date(timestamp || "")

    if (extra) {
        date.setDate(date.getDate() + extra)
    }

    return date.toISOString().split("T")[0].split("-").reverse().join(".")
}

export const handleError = (error: string) => {
    console.error(`So this happened - ${error}`)
}

export const formatDateTime = (date: string) => {
    return new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric"
    })
}
