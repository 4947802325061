import React, {lazy, useContext, useEffect, useState} from "react"
import {endpoint} from "../../variables"
import {useNavigate, useLocation} from "react-router-dom"
import {customersPath} from "../Customers/Customers"
import {Axios} from "../../Axios"
import {getUrlParams} from "../../utils/_helpers"
import "./Customer.sass"
import {getCustomer} from "../../utils/Getters/getCustomer"
import Loader from "../_shared/Loader/Loader"
import Button from "../_shared/Form/Button/Button"
import {Context} from "../../contexts/Context"
import {CustomerIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"
import {useTranslation} from "react-i18next"
import {handleError} from "../../shared/helpers"

export const customerPath = "/customer/"

const Table = lazy(() => import("./Table"))

interface UpdateCustomerIF {
    input?: CustomerIF
    force?: boolean
}

const Customer = () => {
    const {t} = useTranslation()
    const [customer, setCustomer] = useState<CustomerIF>({
        id: 0
    })
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber", "id"])
    const page = parseInt(path["pageNumber"]) || 1
    const customerNumber = parseInt(path["id"])
    const title = customer && customer.id ? `${t("customers.singular")} #${customer.id}` : `${t("new")} ${t("customers.singular")}`
    const [loader, setLoader] = useState(true)
    const {confirmation, notification} = useContext(Context)

    useEffect(() => {
        if (!customerNumber) {
            setCustomer({
                id: 0
            })

            setLoader(false)

            return
        }

        getCustomer({
            customerNumber,
            callback: (data: CustomerIF) => {
                setCustomer(data)
                setLoader(false)
            }
        })
    }, [customerNumber])

    const updateCustomer = ({input, force}: UpdateCustomerIF) => {
        const putCustomer = input || customer

        Axios.put(`${endpoint}${customerPath}${putCustomer.id}/`, putCustomer)
            .catch(error => handleError(error))
    }

    const addCustomer = (force?: boolean) => {
        Axios.post(`${endpoint}${customerPath}`, customer)
            .then(({data}) => {
                const {success, insertId} = data

                if (!success) {
                    handleError(`No luck saving customer ryan, mate! [A]`)
                }

                navigate(`${customerPath}${page}/${insertId}/`)
            })
            .catch(error => handleError(error))
    }

    const doSave = (force = false) => {
        if (customer.id > 0) {
            updateCustomer({force})
        } else {
            addCustomer(force)
        }
    }

    const onChange = (name: string, value: string) => {
        if (customer.id === 0) {
            return
        }

        const newCustomer = {
            ...customer,
            [name]: value
        }

        setCustomer(newCustomer)
        updateCustomer({input: newCustomer})
    }

    const deleteButton = () => {
        if (!customer || !customer.id) {
            return
        }

        return <Button text={t("button.delete")} additionalClassName="large tertiary"
                       onClick={(event: MouseEvent) => handleDeleteItem({
                           event,
                           confirmation,
                           id: customer.id,
                           navigate,
                           resource: "customer"
                       })}></Button>
    }

    return (
        loader ?
            <Loader/>
            :
            <div>
                <section className="component customer">
                    <header>
                        <h1>{title}</h1>

                        <div className="actions">
                            <Button additionalClassName="primary large"
                                    text={t("button.save")}
                                    onClick={() => {
                                        doSave()
                                        notification?.update({
                                            show: true
                                        })
                                    }}
                            ></Button>
                            <Button additionalClassName="secondary large"
                                    text={t("button.goBack")}
                                    onClick={() => navigate(`${customersPath}${page}`)}
                            ></Button>
                            {deleteButton()}
                        </div>
                    </header>

                    <Table customer={customer} onChange={onChange}/>
                </section>

                {/* TODO set here worksheets? Invoices? */}
                {/*{customer.id ? <Orders customer={customer.id}/> : ""}*/}

            </div>
    )
}

export default Customer
