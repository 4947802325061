import React, {KeyboardEvent, lazy, ReactNode, useEffect, useRef, useState} from "react"
import {customerPath} from "../../Customer/Customer"
import Loader from "../Loader/Loader"
import Pagination from "@mui/material/Pagination"
import {capitalizeFirst, ConditionalWrapper, getUrlParams} from "../../../utils/_helpers"
import {useLocation, useNavigate} from "react-router-dom"
import {customersPath} from "../../Customers/Customers"
import {orderPath} from "../../Order/Order"
import {ordersPath} from "../../Orders/Orders"
import "../../../assets/stylessheets/shared/pagination.sass"
import Search from "../Icons/Search"
import "./DefaultList.sass"
import {endpoint} from "../../../variables"
import {Axios} from "../../../Axios"
import {productsPath} from "../../Products/Products"
import {productPath} from "../../Product/Product"
import {CustomerIF, ListButtonIF, MetaIF, OrderIF} from "../../../../types"
import {useTranslation} from "react-i18next"
import {handleError} from "../../../shared/helpers"
import {worksheetsPath} from "../../Worksheets/Worksheets"
import {worksheetPath} from "../../Worksheet/Worksheet"
import {worksheetsPerCustomer} from "../../Worksheets/WorksheetsPerCustomer"
import {invoicePath} from "../../Invoice/Invoice"
import {invoicesPath} from "../../Invoices/Invoices"

const Button = lazy(() => import("../Form/Button/Button"))

interface ModalIF {
    header?: string
    cssClass?: string
    body?: ReactNode
    footer?: string | ReactNode,
    closeButton?: boolean
}

interface DefaultListIF {
    list: string
    loader: boolean
    setLoader: Function
    renderer: string | JSX.Element
    meta: null | MetaIF
    page: number
    callback: Function
    id?: number
    addAction?: ModalIF | Function
    compact?: boolean
    title?: string
    buttons?: any
}

const DefaultList = ({
                         list,
                         loader,
                         setLoader,
                         renderer,
                         meta,
                         page,
                         callback,
                         compact,
                         title,
                         buttons
                     }: DefaultListIF) => {
    const navigate = useNavigate()
    let path: string = ""
    let paths: string = ""
    const [showSearch, setShowSearch] = useState(false)
    const searchField = useRef(null)
    const [keyword, setKeyword] = useState<string | null>(null)
    const {t} = useTranslation()
    const isWorksheetsPerCustomer = list === worksheetsPerCustomer
    const urlLocation = useLocation()
    const urlPath = getUrlParams(urlLocation.pathname, ["page", "pageNumber", "customerId", "worksheetPage"])
    const urlPage = parseInt(urlPath["pageNumber"]) || 1
    const customerId = parseInt(urlPath["customerId"])

    switch (list) {
        case "customers":
            path = customerPath
            paths = customersPath

            break
        case "orders":
            path = orderPath
            paths = ordersPath

            break
        case "worksheets":
            path = worksheetPath
            paths = worksheetsPath

            break
        case worksheetsPerCustomer:
            path = worksheetPath
            paths = worksheetsPath

            break
        case "products":
            path = productPath
            paths = productsPath

            break
        case "invoices":
            path = invoicePath
            paths = invoicesPath

            break
        default:
            break
    }

    useEffect(() => {
        if (!keyword) {
            return
        }

        Axios.get(`${endpoint}/search${paths}${encodeURIComponent(keyword)}/${page}/${customerId ? `${customerId}/` : ""}`)
            .then(({data}) => {
                callback(data as Array<OrderIF> | Array<CustomerIF>)
            })
            .catch(error => handleError(error))
    }, [keyword, page, paths])

    const handleEscape = (event: KeyboardEvent<HTMLInputElement>) => {
        const backspace = "Backspace"
        const allowedKeys = ["Escape", backspace]

        if (!allowedKeys.includes(event.key) || !searchField.current) {
            return
        }

        if (event.key === backspace) {
            if (!keyword) {
                setKeyword("")
                callback(false)
            }

            return
        }

        if (keyword) {
            setKeyword("")
            callback(false)
        } else {
            setShowSearch(false)
        }
    }

    const showSearchField = () => {
        if (!showSearch) {
            return ""
        }

        return (
            <input autoFocus placeholder={t("search")}
                   className="search"
                   value={keyword || ""}
                   onChange={event => setKeyword(event.currentTarget.value)}
                   onKeyUp={handleEscape}
                   ref={searchField}
            />
        )
    }

    const renderButton = ({text, classname, onClick, Dropdown}: ListButtonIF, index: number) => {
        const isSearch = text.includes("search")

        return (
            <ConditionalWrapper
                condition={isSearch}
                wrapper={(children) => <div className="search-wrapper">{children}</div>}
                key={index}
            >
                {isSearch && showSearchField()}
                <ConditionalWrapper
                    condition={Dropdown}
                    wrapper={(children) => <div className="button-wrapper">{children}</div>}
                    key={index}
                >
                    <Button additionalClassName={`large ${classname || "primary"}`}
                            text={isSearch ? undefined : t(text)}
                            onClick={isSearch ? () => setShowSearch(true) : onClick}
                            Element={isSearch ? Search : undefined}
                    />
                    {Dropdown && <Dropdown/>}
                </ConditionalWrapper>
            </ConditionalWrapper>
        )
    }

    const currentButtons = buttons || [
        {
            text: "button.search"
        },
        {
            text: "button.add",
            onClick: () => navigate(`${path}${page}/0/`)
        }
    ]

    return (
        <section className={`component ${list}`}>
            <header>
                <h1>{compact ? `${t("latest")} ` : ""}{capitalizeFirst(t(`${isWorksheetsPerCustomer ? "worksheets" : list}.plural`))}{title ? ` - ${title}` : ""}</h1>

                {!compact &&
                    <div className="actions">
                        {currentButtons.map((item: ListButtonIF, index: number) => renderButton(item, index))}
                    </div>}
            </header>

            {loader ?
                <Loader additionalClass="relative"/>
                :
                <>
                    {renderer}

                    {meta && !compact && <Pagination count={meta.total}
                                                     page={page}
                                                     color="primary"
                                                     onChange={(_event, value) => {
                                                         if (page !== value) {
                                                             setLoader(true)
                                                         }

                                                         navigate(`${paths}${isWorksheetsPerCustomer ? `${urlPage}/${customerId}/` : ""}${value}/`)
                                                     }}/>}
                </>
            }
        </section>
    )
}

export default DefaultList
