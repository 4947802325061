import React, {useState} from "react"
import {languages} from "../../../i18n"
import {useTranslation} from "react-i18next"
import "./LanguageSelector.sass"

export const languageStorage = "language"

const LanguageSelector = () => {
    const {t, i18n} = useTranslation()
    const {resources: languageList, lng: defaultLanguage} = languages
    const localLanguage = localStorage.getItem(languageStorage) || defaultLanguage
    const [currentLanguage, setCurrentLanguage] = useState(localLanguage)

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language)
            .then((test) => {
                setCurrentLanguage(language)
                localStorage.setItem(languageStorage, language)
            })
    }

    return (
        <ul className="language-selector">
            {Object.keys(languageList).map((item, index) => {
                return <li className={currentLanguage === item ? "active" : ""}
                           key={index}
                           onClick={() => changeLanguage(item)}
                >
                    {t(`languages.${item}`)}
                </li>
            })}
        </ul>
    )
}

export default LanguageSelector
