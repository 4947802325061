import {endpoint} from "../../variables"
import {Axios} from "../../Axios"
import {customersPath} from "../../components/Customers/Customers"
import {customerPath} from "../../components/Customer/Customer"
import {GetterIF} from "../../../types"
import {handleError} from "../../shared/helpers"

export const getCustomers = ({callback, data}: GetterIF) => {
    let fullEndpoint = endpoint

    if (!data) {
        fullEndpoint = `${fullEndpoint}${customersPath}`
    } else if (typeof data === "number") {
        fullEndpoint = `${fullEndpoint}${customerPath}${data}/`
    } else {
        const {limit} = data
        const start = data.start || 0

        fullEndpoint = `${fullEndpoint}${customersPath}${start}/${limit}/`
    }

    Axios.get(fullEndpoint)
        .then(({data}) => {
            const {resource} = data

            callback(resource)
        })
        .catch(error => handleError(error))
}
