import {deleteItem} from "../utils/Delete/deleteItem"
import {ConfirmationIF, CustomerIF, OrderIF, ProductIF, WorksheetIF} from "../../types"
import {NavigateFunction} from "react-router-dom"

interface HandleDeleteItemIF {
    event: Event
    confirmation: ConfirmationIF
    id: number
    list?: ProductIF[] | OrderIF[] | CustomerIF[] | WorksheetIF[] | null | undefined
    setList?: Function
    navigate?: NavigateFunction
    resource: string
    callback?: Function
}

export const handleDeleteItem = ({event, confirmation, id, list, setList, navigate, resource, callback}: HandleDeleteItemIF) => {
    event.stopPropagation()

    confirmation.update({
        ...confirmation,
        show: true,
        resource,
        confirm: () => deleteItem({
            confirmation,
            id,
            list,
            setList,
            navigate,
            resource,
            callback
        })
    })
}
