import React, {useContext, useEffect, useRef, useState} from "react"
import {endpoint} from "../../variables"
import {useNavigate, useLocation} from "react-router-dom"
import {Axios} from "../../Axios"
import {getUrlParams} from "../../utils/_helpers"
import Loader from "../_shared/Loader/Loader"
import Button from "../_shared/Form/Button/Button"
import {productsPath} from "../Products/Products"
import {Context} from "../../contexts/Context"
import {ProductIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"
import {useTranslation} from "react-i18next"
import {handleError} from "../../shared/helpers"
import {AuthContext} from "../../contexts/Auth"
import CreatableSelect from "react-select/creatable"

export const productPath = "/product/"

interface FieldItemIF {
    name: string
    select?: boolean
    discount?: boolean
}

const Product = () => {
    const {t} = useTranslation()
    const [product, setProduct] = useState<ProductIF>({
        id: 0
    })
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber", "id"])
    const page = parseInt(path["pageNumber"]) || 1
    const productNumber = parseInt(path["id"])
    const title = product && product.id ? `${t("products.singular")} #${product.id}` : `${t("new")} ${t("products.singular")}`
    const [loader, setLoader] = useState(true)
    const units = [
        {label: t("products.form.pc"), value: "pc"},
        {label: t("products.form.set"), value: "set"},
        {label: t("products.form.hour"), value: "hour"},
        {label: "m", value: "m"},
        {label: "km", value: "km"}
    ]
    const {confirmation, notification} = useContext(Context)
    const {rootState: {isAuth, theUser}} = useContext(AuthContext)
    const refs = useRef<{ [key: string]: any | null }>({})
    const setRef = (key: string) => (element: any | null) => {
        refs.current[key] = element
    }
    const fields = [
        {name: "code"},
        {name: "name"},
        {name: "price"},
        {name: "discountPrice", discount: true},
        {name: "unit", select: true},
        {name: "comment"}
    ]

    useEffect(() => {
        if (!isAuth) {
            return
        }

        if (!productNumber) {
            setProduct({
                id: 0
            })

            setLoader(false)

            return
        }

        Axios.get(`${endpoint}${productPath}${productNumber ? `${productNumber}/` : ""}`)
            .then(({data}) => {
                const {resource} = data

                setProduct(resource)
                setLoader(false)
            })
            .catch(error => handleError(error))
    }, [isAuth, productNumber])

    const getProduct = () => {
        const newProduct = {}

        Object.keys(refs.current).forEach(item => {
            const target = refs.current[item]
            let value

            try {
                value = target?.getValue()[0].value || ""
            } catch (e) {}

            value = target?.value || value

            newProduct[item] = value
        })

        return newProduct
    }

    const updateProduct = (input?: ProductIF) => {
        const item = input || getProduct()

        Axios.put(`${endpoint}${productPath}${product.id}/`, item)
            .catch(error => handleError(error))
    }

    const addProduct = () => {
        const newProduct = getProduct()

        Axios.post(`${endpoint}${productPath}`, newProduct)
            .then(({data}) => {
                const {success, insertId} = data

                if (!success) {
                    handleError(`No luck saving customer ryan, mate! [A]`)
                }

                navigate(`${productPath}${page}/${insertId}/`)
            })
            .catch(error => handleError(error))
    }

    const doSave = () => {
        if (product.id > 0) {
            updateProduct()
        } else {
            addProduct()
        }
    }

    const onChange = (input: Event | string) => {
        let newProduct

        if (product.id === 0) {
            return
        }

        if (typeof input === "string") {
            newProduct = {
                ...product,
                unit: input
            }
        } else {
            const element = input.target as HTMLInputElement

            if (!element) {
                return
            }

            newProduct = {
                ...product,
                [element.name]: element.value
            }
        }

        updateProduct(newProduct)
    }

    const deleteButton = () => {
        if (!product || !product.id) {
            return
        }

        return <Button text={t("button.delete")} additionalClassName="large tertiary"
                       onClick={(event: MouseEvent) => handleDeleteItem({
                           event,
                           confirmation,
                           id: product.id,
                           navigate,
                           resource: "product"
                       })}></Button>
    }

    const renderField = (item: FieldItemIF, index: number) => {
        const {name, select, discount} = item
        const value = product?.[name]
        const currentRef = setRef(name)

        if (discount && !theUser.discount) {
            return null
        }

        return (
            <div className="field" key={index}>
                <label htmlFor={name}>{t(`products.form.${name}`)}</label>

                {select ?
                    <CreatableSelect
                        className="select"
                        classNamePrefix="select"
                        defaultValue={units.filter((item: any) => item.value === product.unit)[0] || units[0]}
                        name={name}
                        id={name}
                        options={units}
                        isValidNewOption={() => false}
                        ref={currentRef}
                        onChange={product => onChange(product?.value as string)}
                    />
                    :
                    <input
                        name={name}
                        id={name}
                        ref={currentRef}
                        defaultValue={value}
                        onChange={event => onChange(event as unknown as Event)}
                    />
                }
            </div>
        )
    }

    return (
        loader ?
            <Loader/>
            :
            <>
                <section className="component customer">
                    <header>
                        <h1>{title}</h1>

                        <div className="actions">
                            <Button additionalClassName="primary large"
                                    text={t("button.save")}
                                    onClick={() => {
                                        doSave()
                                        notification?.update({
                                            show: true
                                        })
                                    }}></Button>
                            <Button additionalClassName="secondary large"
                                    text={t("button.goBack")}
                                    onClick={() => navigate(`${productsPath}${productNumber ? page : "1"}/`)}
                            ></Button>
                            {deleteButton()}
                        </div>
                    </header>

                    <form className="general-form product">
                        {fields.map((item, index) => renderField(item as FieldItemIF, index))}

                        <input type="submit" className="hidden"/>
                    </form>
                </section>
            </>
    )
}

export default Product
