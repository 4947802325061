import React from "react"
import {ReactNode} from "react"
import {SelectOptionIF, UserResponseIF} from "../../types"

interface ConditionalWrapperProps {
    condition: boolean
    wrapper: (children: ReactNode) => JSX.Element
    children: ReactNode
}

export const isObjectEmpty = (obj: UserResponseIF | null) => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}

export const getUrlParams = (url: string, mapper: Array<string>) => {
    const split = url.split("/").filter(Boolean)
    const mapped: Array<string> = []

    if (mapper) {
        mapper.forEach((key: string, index: number) => {
            mapped[key] = split[index]
        })

        return mapped
    }

    return split
}

export const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const createOptionsList = (data: any, value?: string) => {
    const array: Array<SelectOptionIF> = []

    data.forEach((item: any) => array.push({
        label: item["name"] || "",
        value: item[value ? value : "id"]
    }))

    return array
}

export const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailRegex.test(email)
}

export const ConditionalWrapper = ({condition, wrapper, children}: ConditionalWrapperProps) => {
    return condition ? wrapper(children) : <>{children}</>
}
