import React, {useState, useEffect, useCallback} from "react"
import {endpoint} from "../../variables"
import {useLocation, useNavigate} from "react-router-dom"
import {getUrlParams} from "../../utils/_helpers"
import DefaultList from "../_shared/DefaultList/DefaultList"
import {handleError, isEmpty} from "../../shared/helpers"
import NoEntries from "../_shared/NoEntries/NoEntries"
import Actions from "../_shared/Actions/Actions"
import {DataResponseIF, MetaIF, ProductIF, WorksheetIF} from "../../../types"
import {useTranslation} from "react-i18next"
import {Axios} from "../../Axios"

export const worksheetsPath = "/worksheets/"

interface WorksheetsIF {
    compact?: boolean
}

const Worksheets = ({compact}: WorksheetsIF) => {
    const [worksheets, setWorksheets] = useState<Array<WorksheetIF> | null>(null)
    const [meta, setMeta] = useState<MetaIF | null>(null)
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const page = parseInt(path["pageNumber"]) || 1
    const [loader, setLoader] = useState(true)
    const [unfilteredData, setUnfilteredData] = useState<DataResponseIF | null>(null)
    const [firstRender, setFirstRender] = useState(true)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const fetchData = useCallback(() => {
        Axios.get(`${endpoint}${worksheetsPath}${page ? `${page}/` : ""}`)
            .then(({data}) => {
                setLoader(false)
                setMeta(data.meta)
                setWorksheets(data.resource)
                setUnfilteredData(data)
            })
            .catch(error => handleError(error))
    }, [page])

    useEffect(() => {
        if (!firstRender) {
            fetchData()
        }
    }, [fetchData, firstRender])

    useEffect(() => {
        setFirstRender(false)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleViewClick = (id: number) => {
        navigate(`${worksheetsPath}${page}/${id}/1/`)
    }

    const showTable = () => {
        let itemsList = []

        if (!worksheets) {
            return ""
        }

        itemsList.push(Object.values(compact ? worksheets.slice(0, 5) : worksheets).map((item: any, index) => {
            const {id, worksheet_count, name} = item

            return (
                <tr className="row" key={index}>
                    <td onClick={() => handleViewClick(id)} className="name">
                        {name}
                    </td>
                    <td onClick={() => handleViewClick(id)} className="items">
                        {worksheet_count}
                    </td>
                    {!compact && <td className="actions">
                        <Actions compact={compact}
                                 id={id}
                                 viewCallback={handleViewClick}
                        />
                    </td>}
                </tr>
        )
        }))

        return itemsList
    }

    const showList = () => {
        if (isEmpty(worksheets)) {
            return <NoEntries/>
        }

        return (
            <table className={`general-table worksheet-table${compact ? " compact" : ""}`}>
                <thead>
                <tr>
                    <th className="name">{t("worksheets.form.customer")}</th>
                    <th className="items">{t("worksheets.form.products")}</th>
                    {!compact && <th className="actions"/>}
                </tr>
                </thead>
                <tbody>
                {showTable()}
                </tbody>
            </table>
        )
    }

    const handleCallback = (data: DataResponseIF) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        setWorksheets(data ? data.resource as Array<ProductIF> : unfilteredData.resource as Array<ProductIF>)
    }

    return (
        <DefaultList list="worksheets"
                     loader={loader}
                     setLoader={setLoader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     compact={compact}
                     callback={(data: DataResponseIF) => handleCallback(data)}
        />
    )
}

export default Worksheets
