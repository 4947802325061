import React, {useRef, useState} from "react"
import "./Actions.sass"
import {useOutsideAlerter} from "../../../utils/Hooks/clickOutside"
import {useTranslation} from "react-i18next"

interface ActionsIF {
    id: number
    compact?: boolean | undefined
    viewCallback?: Function
    deleteCallback?: Function
    commentCallback?: () => void
}

const Actions = ({
                     compact,
                     id,
                     viewCallback,
                     deleteCallback,
                     commentCallback
                 }: ActionsIF) => {
    const [showActions, setShowActions] = useState(false)
    const actionsRef = useRef(null)
    const {t} = useTranslation()

    useOutsideAlerter(actionsRef, () => setShowActions(false))

    const handleActionClick = (event: Event) => {
        event.preventDefault()

        setShowActions(!showActions)
    }

    if (compact) {
        return <></>
    }

    return (
        <div ref={actionsRef} onClick={(event) => handleActionClick(event as unknown as Event)}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>

            {showActions &&
                <ul className="action-list">
                    {commentCallback && <li onClick={commentCallback}>{t("button.comment")}</li>}
                    {viewCallback && <li onClick={() => viewCallback(id)}>{t("button.view")}</li>}
                    {deleteCallback &&
                        <li onClick={(event) => deleteCallback(event, id)}>{t("button.delete")}</li>}
                </ul>
            }
        </div>
    )
}

export default Actions
