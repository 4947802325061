import React, {useEffect, useState} from "react"
import {useLocation} from "react-router"
import {getUrlParams} from "../../utils/_helpers"
import "../Invoice/Invoice.sass"
import {Document, Page, PDFViewer, StyleSheet, Text, View, Image, Font} from "@react-pdf/renderer"
import {formatDateTime, handleError} from "../../shared/helpers"
import {CustomerIF, InvoiceIF, ItemIF, SettingsIF} from "../../../types"
import {Axios} from "../../Axios";
import {endpoint} from "../../variables";
import {useTranslation} from "react-i18next"

export const pdfPath = "/pdf/"

const Pdf = () => {
    const {t} = useTranslation()
    const urlLocation = useLocation()
    const url = getUrlParams(urlLocation.pathname, ["page", "hash"])
    const hash: string = url["hash"]
    const [invoice, setInvoice] = useState<InvoiceIF | null>(null)
    const [customer, setCustomer] = useState<CustomerIF | null>(null)
    const [items, setItems] = useState([])
    const [vat, setVat] = useState(0)
    let totalTotal = 0
    const [settings, setSettings] = useState<SettingsIF | null>(null)

    useEffect(() => {
        if (!hash) {
            return
        }

        Axios.get(`${endpoint}${pdfPath}/${hash}/`)
            .then(({data}) => {
                const {resource} = data

                if (!resource) {
                    handleError(`No success on getting pdf`)

                    return
                }

                setInvoice(resource)
                setItems(resource.items)
                setVat(resource.vat)
                setSettings(resource.settings)
                setCustomer(resource.customer)
            })
            .catch(error => handleError(error))
    }, [hash])

    Font.register({
        family: "Roboto",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
            },
            {
                src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
                fontWeight: "bold",
            }
        ],
    })

    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 110,
            paddingHorizontal: 30,
            letterSpacing: 0.2,
            fontFamily: "Roboto"
        },
        header: {
            flexDirection: "row",
            borderBottom: "1px solid #7f8c8d",
            paddingBottom: 15,
            fontSize: 10,
            lineHeight: 1.8
        },
        logoContainer: {
            flex: "1 0 50%",
            paddingBottom: 12,
            alignItems: "flex-end"
        },
        logo: {
            height: 100,
            paddingBottom: 15
        },
        headerText: {
            marginTop: "auto"
        },
        h3: {
            fontSize: 14,
            paddingBottom: 14
        },
        footer: {
            textAlign: "center",
            paddingTop: 10,
            borderTop: "1px solid #7f8c8d",
            fontSize: 10,
            lineHeight: 2,
            position: "absolute",
            bottom: 10,
            left: 30,
            right: 30
        },
        bold: {
            fontWeight: "bold"
        },
        left: {
            textAlign: "left",
        },
        center: {
            textAlign: "center",
        },
        right: {
            textAlign: "right",
        },
        columns: {
            flexDirection: "row",
            justifyContent: "space-between",
            width: 180
        },
        value: {
            paddingLeft: 12,
        },
        items: {
            fontSize: 11,
            paddingTop: 20
        },
        item: {
            flexDirection: "row"
        },
        cell: {
            paddingVertical: 4
        },
        number: {
            width: 20
        },
        code: {
            width: 80
        },
        name: {
            width: "50%",
        },
        unit: {
            width: 50,
            textAlign: "center"
        },
        quantity: {
            width: 60,
            textAlign: "right",
        },
        price: {
            width: 70,
            textAlign: "right",
        },
        total: {
            width: 70,
            textAlign: "right",
        },
        totalContainer: {
            paddingTop: 40,
        },
        totalItem: {
            flexDirection: "row",
            justifyContent: "space-between",
            width: "50%",
            marginLeft: "50%",
            fontSize: 12,
            lineHeight: 1.5
        },
        disclaimer: {
            fontSize: 12,
            textAlign: "center",
            paddingVertical: 10
        },
        footerItem: {
            flexDirection: "row",
            justifyContent: "space-between"
        },
        perTwo: {
            width: "50%"
        },
        perThree: {
            width: "33%"
        }
    })

    return (
        <PDFViewer>
            <Document>
                <Page style={styles.body}>
                    <View style={styles.header} fixed>
                        <View style={{...styles.headerText}}>
                            <Text style={{...styles.h3, ...styles.bold}}>{t("pdf.client")}:</Text>
                            <Text>{customer?.name}</Text>
                            <Text>{customer?.phone}</Text>
                            <Text>{customer?.email}</Text>
                            <Text>{customer?.address}</Text>
                        </View>
                        <View style={{...styles.logoContainer, ...styles.right}}>
                            ${settings?.logo && <Image src={`${endpoint}/uploads/${settings?.logo}`} style={styles.logo}/>}

                            <View style={styles.columns}>
                                <Text style={{...styles.bold, ...styles.h3}}>{t("pdf.invoiceNr")}:</Text>
                                <Text
                                    style={{...styles.value, ...styles.right, ...styles.h3}}>{invoice?.number}</Text>
                            </View>
                            <View style={styles.columns}>
                                <Text style={styles.bold}>{t("pdf.date")}:</Text>
                                <Text
                                    style={{...styles.value, ...styles.right}}>{formatDateTime(invoice?.invoiceDate as unknown as string)}</Text>
                            </View>
                            <View style={styles.columns}>
                                <Text style={styles.bold}>{t("pdf.due")}:</Text>
                                <Text
                                    style={{...styles.value, ...styles.right}}>{formatDateTime(invoice?.invoiceDate as unknown as string)}</Text>
                            </View>
                            <View style={styles.columns}>
                                <Text style={styles.bold}>{t("pdf.penalty")}:</Text>
                                <Text style={{...styles.value, ...styles.right}}>0.1% {t("pdf.perDay")}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.items}>
                        <View style={styles.item}>
                            <Text style={{...styles.number, ...styles.cell, ...styles.bold}}>#</Text>
                            <Text style={{...styles.code, ...styles.cell, ...styles.bold}}>{t("pdf.code")}</Text>
                            <Text style={{...styles.name, ...styles.cell, ...styles.bold}}>{t("pdf.name")}</Text>
                            <Text style={{...styles.unit, ...styles.cell, ...styles.bold}}>{t("pdf.unit")}</Text>
                            <Text
                                style={{...styles.quantity, ...styles.cell, ...styles.bold}}>{t("pdf.quantity")}</Text>
                            <Text style={{...styles.price, ...styles.cell, ...styles.bold}}>{t("pdf.price")}</Text>
                            <Text style={{...styles.total, ...styles.cell, ...styles.bold}}>{t("pdf.total")}</Text>
                        </View>

                        {items.map((item: ItemIF, index: any) => {
                            const {code, name, unit, price, quantity} = item

                            totalTotal = totalTotal + price * quantity

                            return (
                                <View style={styles.item} key={code}>
                                    <Text style={{...styles.number, ...styles.cell}}>{index + 1}</Text>
                                    <Text style={{...styles.code, ...styles.cell}}>{code}</Text>
                                    <Text style={{...styles.name, ...styles.cell}}>{name}</Text>
                                    <Text style={{...styles.unit, ...styles.cell}}>{unit}</Text>
                                    <Text style={{...styles.quantity, ...styles.cell}}>{quantity}</Text>
                                    <Text style={{...styles.price, ...styles.cell}}>{price.toFixed(2)}</Text>
                                    <Text
                                        style={{...styles.total, ...styles.cell}}>{(price * quantity).toFixed(2)}</Text>
                                </View>
                            )
                        })}
                    </View>

                    <View wrap={false}>
                        {/*total*/}
                        <View style={styles.totalContainer}>
                            <View style={styles.totalItem}>
                                <Text style={styles.left}>{t("pdf.total")}:</Text>
                                <Text style={{...styles.price, ...styles.right}}>{totalTotal.toFixed(2)}</Text>
                            </View>
                            <View style={styles.totalItem}>
                                <Text
                                    style={styles.left}>{t("pdf.vat")} {invoice?.vat}%:</Text>
                                <Text style={{...styles.price, ...styles.right}}>{(totalTotal * vat / 100).toFixed(2)}</Text>
                            </View>
                            <View style={styles.totalItem}>
                                <Text
                                    style={{...styles.bold, ...styles.left, ...styles.h3}}>{t("pdf.toPay")}:</Text>
                                <Text
                                    style={{...styles.bold, ...styles.price, ...styles.right, ...styles.h3}}>{(totalTotal + (totalTotal * vat / 100)).toFixed(2)}</Text>
                            </View>

                            <Text style={styles.disclaimer}>{settings?.pdfDisclaimer}</Text>
                        </View>
                    </View>

                    <View style={styles.footer} fixed>
                        <View style={styles.footerItem}>
                            <Text style={{...styles.left, ...styles.perThree}}>{settings?.companyName}</Text>
                            <Text style={{...styles.center, ...styles.perThree}}>{settings?.companyEmail}</Text>
                            <Text style={{...styles.right, ...styles.perThree}}>{settings?.companyPhone}</Text>
                        </View>
                        <View style={styles.footerItem}>
                            <Text style={{...styles.left, ...styles.perThree}}>{settings?.companyAddress1}</Text>
                            <Text style={{...styles.center, ...styles.perThree}}>{settings?.companyWebsite}</Text>
                            <Text style={{...styles.right, ...styles.perThree}}>{t("pdf.regNr")}: {settings?.companyRegNr}</Text>
                        </View>
                        <View style={styles.footerItem}>
                            <Text style={{...styles.left, ...styles.perTwo}}>{settings?.companyAddress2}</Text>
                            <Text style={{...styles.right, ...styles.perTwo}}>{t("pdf.vatNr")}: {settings?.companyVatNr}</Text>
                        </View>
                        <View style={styles.footerItem}>
                            <Text style={{...styles.left, ...styles.perThree}}>{settings?.companyBankAccount}</Text>
                            <Text style={{...styles.center, ...styles.perThree}} render={({pageNumber, totalPages}) => (
                                `${pageNumber} / ${totalPages}`
                            )} fixed/>
                            <Text style={{...styles.right, ...styles.perThree}}>{settings?.companyBankAccount2}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default Pdf
