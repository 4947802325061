import React from "react"
import "./NoEntries.sass"
import {useTranslation} from "react-i18next"

const NoEntries = () => {
    const {t} = useTranslation()

    return (
        <div className="no-entries">{t("emptyList")}</div>
    )
}

export default NoEntries
