import {endpoint} from "../../variables"
import {Axios} from "../../Axios"

import {handleError} from "../../shared/helpers"
import {settingsPath} from "../../components/Settings/Settings"

interface GetSettingsIF {
    callback: Function
}

export const getSettings = ({callback}: GetSettingsIF) => {
    Axios.get(`${endpoint}${settingsPath}`)
        .then(({data}) => {
            const {resource} = data

            if (!resource) {
                handleError(`No success on getting settings`)

                return
            }

            callback(resource)
        })
        .catch(error => handleError(error))
}
