import React, {createContext, useState, ReactNode} from "react"
import {ConfirmationIF} from "../../types"

interface NotificationIF {
    show: boolean
    update: Function
    message: string
}

interface ModalIF {
    show: boolean
    update: Function
    header?: string
    cssClass?: string
    body?: ReactNode
    footer?: string | ReactNode,
    closeButton?: boolean
    error?: string
}

interface ContextIF {
    confirmation: ConfirmationIF
    notification?: NotificationIF
    modal: ModalIF
}

interface ContextWrapperIF {
    children: ReactNode
}

export const Context = createContext<ContextIF>({
    confirmation: {
        show: false,
        update: () => {}
    },
    modal: {
        show: false,
        update: () => {}
    },
    notification: {
        show: false,
        update: () => {},
        message: ""
    }
})

const Confirmation = () => {
    const [show, setShow] = useState(false)
    const [props, setProps] = useState({})

    const update = (props: ConfirmationIF) => {
        const {show} = props

        setShow(show as boolean)
        setProps(props)
    }

    return {
        ...props,
        show: show,
        update: update
    }
}

const Notification = () => {
    const [show, setShow] = useState(false)
    const [props, setProps] = useState({})
    const [message, setMessage] = useState("")

    const update = (props: NotificationIF) => {
        const {show, message} = props

        setShow(show as boolean)
        setMessage(message)
        setProps(props)
    }

    return {
        ...props,
        show: show,
        update: update,
        message: message
    }
}

const Modal = () => {
    const [show, setShow] = useState(false)
    const [props, setProps] = useState({})

    const update = (props: ConfirmationIF) => {
        const {show} = props

        setShow(show as boolean)
        setProps(props)
    }

    return {
        ...props,
        show: show,
        update: update
    }
}

const ContextWrapper = ({children}: ContextWrapperIF) => {
    const value = {
        confirmation: Confirmation(),
        notification: Notification(),
        modal: Modal()
    }

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export default ContextWrapper
