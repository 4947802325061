const development = "development"

const productionEndpoint = "https://api.eastsidecustoms.com"
const developmentEndpoint = "http://localhost:3999"

export const isDevelopment = () => process.env.NODE_ENV === development
export const endpoint = isDevelopment() ? developmentEndpoint : productionEndpoint

export const lastUrl = "lastUrl"
export const defaultShipping = "19.95"
export const defaultDiscountShipping = "16.49"
export const themeString = "theme"
export const storageChange = "storageChange"
