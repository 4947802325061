import React, {lazy, useContext, useEffect, useRef, useState} from "react"
import {endpoint} from "../../variables"
import {useNavigate, useLocation} from "react-router-dom"
import {Axios} from "../../Axios"
import {createOptionsList, getUrlParams} from "../../utils/_helpers"
import Loader from "../_shared/Loader/Loader"
import Button from "../_shared/Form/Button/Button"
import {Context} from "../../contexts/Context"
import {CustomerIF, InvoiceIF, InvoiceProductIF, SelectOptionIF} from "../../../types"
import {useTranslation} from "react-i18next"
import {handleError} from "../../shared/helpers"
import {invoicesPath} from "../Invoices/Invoices"
import {getProducts} from "../../utils/Getters/getProducts"
import {getCustomers} from "../../utils/Getters/getCustomers"
import paidIcon from "../../assets/images/paid.svg"
import {pdfPath} from "../Pdf/Pdf"

export const invoicePath = "/invoice/"

const Details = lazy(() => import("./Details"))
const Items = lazy(() => import("./Items"))

const Invoice = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber", "id"])
    const page = parseInt(path["pageNumber"]) || 1
    const invoiceId = parseInt(path["id"])
    const [loader, setLoader] = useState(true)
    const {confirmation, notification} = useContext(Context)
    const [invoice, setInvoice] = useState<InvoiceIF>()
    const customerRef = useRef<HTMLInputElement>(null)
    const dateRef = useRef<HTMLInputElement>(null)
    const [currentItems, setCurrentItems] = useState<InvoiceProductIF[] | null>(null)
    const [products, setProducts] = useState<InvoiceProductIF[]>([])
    const [shortProducts, setShortProducts] = useState<SelectOptionIF[]>([])
    const [customers, setCustomers] = useState<Array<SelectOptionIF>>([])
    const [customer, setCustomer] = useState<SelectOptionIF>()

    useEffect(() => {
        getCustomers({
            callback: (data: Array<CustomerIF>) => {
                setCustomers(createOptionsList(data))
            }
        })
    }, [])

    useEffect(() => {
        if (!customers || !invoice) {
            return
        }

        setCustomer(customers.filter(customer => parseInt(customer.value) === invoice.customerId)[0])
    }, [customers, invoice])

    const fetchData = (invoiceOnly = false) => {
        Axios.get(`${endpoint}${invoicePath}${invoiceId}/`)
            .then(({data}) => {
                const {resource} = data

                if (!resource) {
                    handleError(`No success on getting invoice`)

                    return
                }

                if (!invoiceOnly) {
                    setCurrentItems(resource.items)
                }

                setInvoice(resource)
                setLoader(false)
            })
            .catch(error => handleError(error))
    }

    useEffect(() => {
        fetchData()
    }, [invoiceId])

    useEffect(() => {
        getProducts({
            callback: ({resource}: any) => {
                setProducts(resource)
                setShortProducts(createOptionsList(resource, "id"))
            }
        })
    }, [])

    const invoiceIsLocked = invoice?.locked === 1

    const markAsPaid = () => {
        // set locked in invoices
        // remove rows from worksheets where invoice ID matches
        confirmation.update({
            ...confirmation,
            show: true,
            title: "modal.paid.title",
            text: "modal.paid.text",
            showResource: false,
            icon: paidIcon,
            confirm: () => {
                Axios.put(`${endpoint}${invoicePath}paid/${invoiceId}/`)
                    .then(({data}) => {
                        const {success} = data

                        if (!success) {
                            handleError(`No luck saving worksheet ryan, mate!`)
                        }

                        const newInvoice = {
                            ...invoice,
                            locked: 1
                        }

                        setInvoice(newInvoice as InvoiceIF)
                    })
                    .catch(error => handleError(error))
            }
        })
    }

    const generateRandomHashFromString = async (input: string) => {
        // Add randomness by appending a random salt to the input string
        const salt = Math.random().toString(36).substring(2, 10) // 8-char random salt
        const inputWithSalt = input + salt

        // Encode the input and compute the hash
        const encoder = new TextEncoder()
        const data = encoder.encode(inputWithSalt)

        // Generate a hash using SHA-256
        const hashBuffer = await crypto.subtle.digest("SHA-256", data)

        // Convert hash to a hex string
        const hashArray = Array.from(new Uint8Array(hashBuffer))
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("")

        return hashHex.substring(0, 32) // Ensure the output is 32 characters
    }

    const redirect = () => {
        window.open(`${pdfPath}${invoice?.hash}/`, "esc_pdf")
    }

    const generatePdf = async () => {
        if (!invoice?.hash) {
            const hash = await generateRandomHashFromString(`${invoice?.number}${invoiceId}`)

            Axios.put(`${endpoint}${pdfPath}${invoiceId}`, {hash})
                .catch(error => handleError(error))
                .finally(() => {
                    redirect()
                })
        } else {
            redirect()
        }
    }

    const sendInvoice = () => {
        console.log("send invoice")
    }

    // add fake empty item to invoice for placeholder
    const addItem = () => {
        if (!currentItems) {
            return
        }

        const emptyItem: InvoiceProductIF = {
            id: 0,
            quantity: 0,
            name: "",
            code: "",
            price: 0,
            discountPrice: 0,
            unit: ""
        }

        setCurrentItems([
            ...currentItems,
            emptyItem
        ])
    }

    // change new item dropdown (save new item into invoice) or change item quantity
    const onChange = (id: number, value: string) => {
        if (!currentItems) {
            return
        }

        if (id) {
            Axios.put(`${endpoint}${invoicePath}${invoiceId}/${id}`, {value})
                .catch(error => handleError(error))
                .finally(() => {
                    setCurrentItems(currentItems.map(item => {
                        if (item.id === id) {
                            item.quantity = Number(value)
                        }

                        return item
                    }))

                    fetchData(true)
                })
        } else {
            const newItem = products.filter(item => item.id === Number(value))[0]

            newItem.price = Number(newItem.price)
            newItem.quantity = 0

            const params = {
                invoiceId,
                customerId: customer?.value,
                items: [{
                    productId: newItem.id,
                    quantity: 0
                }]
            }

            Axios.put(`${endpoint}${invoicePath}${id}/`, params)
                .catch(error => handleError(error))
                .finally(() => {
                    setCurrentItems([
                        ...currentItems.filter(item => item.id),
                        newItem
                    ])

                    fetchData()
                })
        }
    }

    // delete item from invoice
    const onDelete = (_event: Event, id: number) => {
        if (!currentItems) {
            return
        }

        Axios.delete(`${endpoint}${invoicePath}${invoiceId}/${id}/`)
            .then(({data}) => {
                if (!data.success) {
                    handleError(`Failed to delete item in invoice`)
                }

                setCurrentItems(currentItems.filter(item => item.id !== id))
            })
            .catch(error => handleError(error))
    }

    const saveInvoice = (params: InvoiceIF) => {
        Axios.put(`${endpoint}${invoicePath}`, {params})
            .catch(error => handleError(error))
            .finally(() => {
                setInvoice(params)
            })
    }

    const updateDate = (date: Date) => {
        if (!date) {
            return
        }

        saveInvoice({
            ...invoice as InvoiceIF,
            invoiceDate: date
        })
    }

    const updateCustomer = (customer: SelectOptionIF) => {
        saveInvoice({
            ...invoice as InvoiceIF,
            customerId: Number(customer.value)
        })
    }

    const updateType = (type: string) => {
        saveInvoice({
            ...invoice as InvoiceIF,
            type
        })
    }

    const doSave = () => {
        saveInvoice(invoice as InvoiceIF)

        notification?.update({
            show: true
        })
    }

    if (!invoice || currentItems === null) {
        return null
    }

    return (
        loader ?
            <Loader/>
            :
            <div>
                <section className="component invoice">
                    <header>
                        <h1>{t("invoices.singular")} #{invoice?.number}</h1>

                        <div className="actions">
                            {!invoiceIsLocked &&
                                <Button additionalClassName="primary large"
                                        text={t("button.save")}
                                        onClick={() => doSave()}></Button>
                            }
                            <Button additionalClassName="secondary large"
                                    text={t("button.goBack")}
                                    onClick={() => navigate(`${invoicesPath}${invoiceId ? page : "1"}`)}></Button>
                        </div>
                    </header>

                    <Details invoice={invoice}
                             customerRef={customerRef}
                             dateRef={dateRef}
                             customers={customers}
                             customer={customer}
                             updateCustomer={updateCustomer}
                             updateDate={updateDate}
                             updateType={updateType}
                             invoiceIsLocked={invoiceIsLocked}/>
                    <Items items={currentItems}
                           addItem={addItem}
                           onChange={onChange}
                           products={shortProducts}
                           onDelete={onDelete}
                           invoiceIsLocked={invoiceIsLocked}/>

                    <header className="actions">
                        <div className="actions">
                            <Button additionalClassName={`${invoiceIsLocked ? "disabled" : "primary"} large`}
                                    text={t("button.paid")}
                                    onClick={() => markAsPaid()}></Button>
                            <Button additionalClassName="primary large"
                                    text={t("button.pdf")}
                                    onClick={() => generatePdf()}></Button>
                            <Button additionalClassName="primary large"
                                    text={t("button.send")}
                                    onClick={() => sendInvoice()}></Button>
                        </div>
                    </header>
                </section>
            </div>
    )
}

export default Invoice
