import React, {lazy} from "react"
import "./Home.sass"

const Customers = lazy(() => import("../Customers/Customers"))
const Worksheets = lazy(() => import("../Worksheets/Worksheets"))
const Invoices = lazy(() => import("../Invoices/Invoices"))
const Products = lazy(() => import("../Products/Products"))

export const homePath = "/home/"

const Home = () => {
    return (
        <div className="dashboard">
            <Worksheets compact={true}/>
            <Invoices compact={true}/>
            <Products compact={true}/>
            <Customers compact={true}/>
        </div>
    )
}

export default Home
