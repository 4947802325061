import {endpoint} from "../../variables"
import {Axios} from "../../Axios"

import {customerPath} from "../../components/Customer/Customer"
import {handleError} from "../../shared/helpers"

interface GetCustomerIF {
    customerNumber: number
    callback: Function
}

export const getCustomer = ({customerNumber, callback}: GetCustomerIF) => {
    Axios.get(`${endpoint}${customerPath}${customerNumber}/`)
        .then(({data}) => {
            const {resource} = data

            if (!resource) {
                handleError(`No success on getting customer`)

                return
            }

            callback(resource)
        })
        .catch(error => handleError(error))
}
