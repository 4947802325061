import React, {useContext, useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
import {AuthContext, tokenName} from "../../contexts/Auth"
import {homePath} from "../Home/Home"
import Button from "../_shared/Form/Button/Button"
import {handlePasswordSend} from "../../utils/_auth"
import {Axios} from "../../Axios"
import Loader from "../_shared/Loader/Loader"
import "../_shared/Form/Error.sass"
import {Context} from "../../contexts/Context"
import {useTranslation} from "react-i18next"
import {validateEmail} from "../../utils/_helpers"
import {lastUrl} from "../../variables";

export const loginPath = "/login"

interface LoginCredentialsIF {
    username: string
    password: string
}

interface LoginCredentialsErrorIF {
    username: null | string
    password: null | string
}

interface APIResponseIF {
    success: boolean
    error: string
}

export const setAuthentication = ({username, password}: LoginCredentialsIF) => {
    const hashPassword = handlePasswordSend(`${password}`)
    const encode = window.btoa(`${username}:${hashPassword}:${process.env.REACT_APP_API_KEY}`)

    localStorage.setItem(tokenName, encode)
    Axios.defaults.headers.common["Authorization"] = `Basic ${encode}`
}

const Login = () => {
    const {t} = useTranslation()
    const {modal} = useContext(Context)
    const {loginUser} = useContext(AuthContext)
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const header = "Login"
    const usernameRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)

    const doSubmit = async (event: Event) => {
        event.preventDefault()

        const username = usernameRef.current?.value || ""
        const password = passwordRef.current?.value || ""

        // validation
        if (!username || !validateEmail(username) || !password) {
            modal.update({
                ...modal,
                show: true,
                header,
                body: Body({
                    username: username ? "Format" : "Required",
                    password: password ? null : ""
                }),
                footer: Footer()
            })

            return
        }

        const values = {
            username,
            password
        }

        setLoader(true)
        setAuthentication(values)

        loginUser(values)
            .then((response: APIResponseIF) => {
                setLoader(false)

                if (response) {
                    const {success, error} = response

                    if (!success) {
                        localStorage.removeItem(tokenName)

                        modal.update({
                            ...modal,
                            show: true,
                            header,
                            body: Body(),
                            error,
                            footer: Footer()
                        })

                        return
                    }
                }

                modal.update({
                    ...modal,
                    show: false
                })

                const navigateUrl = localStorage.getItem(lastUrl) !== loginPath ? localStorage.getItem(lastUrl) : homePath

                navigate(navigateUrl as string)
            })
    }

    const Body = (error?: LoginCredentialsErrorIF) => {
        return (
            <form onSubmit={(event) => doSubmit(event as unknown as Event)} className="general-form white">
                <div className="field">
                    <label>{t("login.username")}</label>
                    <input
                        ref={usernameRef}
                    />
                    {error && error.username !== null && <div className="error">{t(`login.username${error.username}`)}</div>}
                </div>
                <div className="field">
                    <label>{t("login.password")}</label>
                    <input
                        ref={passwordRef}
                        type="password"
                    />
                    {error && error.password !== null && <div className="error">{t("login.passwordRequired")}</div>}
                </div>
                <input type="submit" className="hidden"/>
            </form>
        )
    }

    const Footer = () => <Button text={t("button.login")} additionalClassName="large primary" onClick={(event: Event) => doSubmit(event)}/>

    useEffect(() => {
        modal.update({
            ...modal,
            show: true,
            header,
            body: Body(),
            footer: Footer()
        })
    }, [])

    return loader ? <Loader/> : null
}

export default Login
