import {endpoint} from "../../variables"
import {Axios} from "../../Axios"
import {customerPath} from "../../components/Customer/Customer"
import {customersPath} from "../../components/Customers/Customers"
import {CustomerIF, DeleteItemIF, OrderIF, ProductIF, WorksheetIF} from "../../../types"
import {productPath} from "../../components/Product/Product"
import {orderPath} from "../../components/Order/Order"
import {handleError} from "../../shared/helpers"
import {worksheetPath} from "../../components/Worksheet/Worksheet"
import {productsPath} from "../../components/Products/Products"
import {ordersPath} from "../../components/Orders/Orders"
import {worksheetsPath} from "../../components/Worksheets/Worksheets"

export const deleteItem = ({confirmation, id, list, setList, navigate, resource, callback}: DeleteItemIF) => {
    confirmation.update({
        show: false
    })
    const mapper = {
        customer: customerPath,
        product: productPath,
        order: orderPath,
        worksheet: worksheetPath
    }
    const mappers = {
        customer: customersPath,
        product: productsPath,
        order: ordersPath,
        worksheet: worksheetsPath
    }

    Axios.delete(`${endpoint}${mapper[resource]}${id}/`)
        .then(({data}) => {
            if (!data.success) {
                handleError(`Failed to delete ${resource}`)

                return
            }

            if (callback) {
                callback()

                return
            }

            if (list) {
                if (!setList) {
                    return
                }

                setList((list as Array<ProductIF | OrderIF | CustomerIF | WorksheetIF>).filter((item: ProductIF | OrderIF | CustomerIF | WorksheetIF) => item.id !== id))
            } else {
                navigate(mappers[resource])
            }
        })
        .catch(error => handleError(error))
}
