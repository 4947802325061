import i18n from "i18next"
import {initReactI18next} from "react-i18next"

import escJSON from "./locales/esc.json"
import etJSON from './locales/et.json'
import enJSON from './locales/en.json'

export const languages = {
    resources: {
        esc: {
            ...escJSON
        },
        et: {
            ...etJSON
        },
        en: {
            ...enJSON
        }
    },
    lng: "en"
}

i18n.use(initReactI18next).init(languages).then()
