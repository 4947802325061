import React, {lazy, Suspense, useEffect} from "react"
import "./assets/stylessheets/application.sass"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import AuthWrapper, {tokenName} from "./contexts/Auth"
import ContextWrapper from "./contexts/Context"
import ThemeContext from "./contexts/Theme"

import {homePath} from "./components/Home/Home"
import {loginPath} from "./components/Login/Login"
import {customersPath} from "./components/Customers/Customers"
import {ordersPath} from "./components/Orders/Orders"
import {orderPath} from "./components/Order/Order"
import {Axios} from "./Axios"
import {customerPath} from "./components/Customer/Customer"
import {invoicePath} from "./components/Invoice/Invoice"
import {productsPath} from "./components/Products/Products"
import {productPath} from "./components/Product/Product"
import {profilePath} from "./components/Profile/Profile"
import {useTranslation} from "react-i18next"
import {languageStorage} from "./components/_shared/Navigation/LanguageSelector"
import {worksheetsPath} from "./components/Worksheets/Worksheets"
import {worksheetPath} from "./components/Worksheet/Worksheet"
import {invoicesPath} from "./components/Invoices/Invoices"
import {settingsPath} from "./components/Settings/Settings"
import {lastUrl} from "./variables"
import {pdfPath} from "./components/Pdf/Pdf"

const PageNotFound = lazy(() => import("./components/PageNotFound/PageNotFound"))
const PrivateRoute = lazy(() => import("./PrivateRoute"))
const Home = lazy(() => import("./components/Home/Home"))
const Login = lazy(() => import("./components/Login/Login"))
const Header = lazy(() => import("./components/_shared/Header/Header"))
const Navigation = lazy(() => import("./components/_shared/Navigation/Navigation"))
const Customers = lazy(() => import("./components/Customers/Customers"))
const Customer = lazy(() => import("./components/Customer/Customer"))
const Orders = lazy(() => import("./components/Orders/Orders"))
const Order = lazy(() => import("./components/Order/Order"))
const Dialog = lazy(() => import("./components/_shared/Dialog/Dialog"))
const Products = lazy(() => import("./components/Products/Products"))
const Product = lazy(() => import("./components/Product/Product"))
const Modal = lazy(() => import("./components/Modal/Modal"))
const Profile = lazy(() => import("./components/Profile/Profile"))
const Worksheet = lazy(() => import("./components/Worksheet/Worksheet"))
const Worksheets = lazy(() => import("./components/Worksheets/Worksheets"))
const WorksheetsPerCustomer = lazy(() => import("./components/Worksheets/WorksheetsPerCustomer"))
const Invoice = lazy(() => import("./components/Invoice/Invoice"))
const Invoices = lazy(() => import("./components/Invoices/Invoices"))
const Notification = lazy(() => import("./components/_shared/Notification/Notification"))
const Settings = lazy(() => import("./components/Settings/Settings"))
const Pdf = lazy(() => import("./components/Pdf/Pdf"))

const App = () => {
    const {i18n} = useTranslation()
    const localLanguage = localStorage.getItem(languageStorage)

    useEffect(() => {
        Axios.defaults.headers.common["Authorization"] = `Basic ${localStorage.getItem(tokenName)}`
        Axios.defaults.headers.common["ApiKey"] = process.env.REACT_APP_API_KEY || ""

        if (localLanguage) {
            i18n.changeLanguage(localLanguage).then()
        }
    }, [i18n, localLanguage])

    localStorage.setItem(lastUrl, window.location.pathname)

    if (window.location.href.includes(pdfPath)) {
        document.getElementById("root")?.classList.add("pdf")
    }

    return (
        <BrowserRouter>
            <Suspense fallback={null}>
                <ThemeContext/>

                <ContextWrapper>
                    <AuthWrapper>
                        <Header/>
                        <Navigation/>

                        <Routes>
                            <Route path="/" element={<PrivateRoute/>}>
                                <Route path="/" element={<Home/>}/>
                            </Route>
                            <Route path={homePath} element={<PrivateRoute/>}>
                                <Route path={homePath} element={<Home/>}/>
                            </Route>

                            {/* products list */}
                            <Route path={productsPath} element={<PrivateRoute/>}>
                                <Route path={productsPath} element={<Products/>}/>
                            </Route>
                            {/* products list with PAGE */}
                            <Route path={`${productsPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${productsPath}:page/`} element={<Products/>}/>
                            </Route>
                            {/* product details */}
                            <Route path={`${productPath}:page/:id/`} element={<PrivateRoute/>}>
                                <Route path={`${productPath}:page/:id/`} element={<Product/>}/>
                            </Route>

                            {/* orders list */}
                            <Route path={ordersPath} element={<PrivateRoute/>}>
                                <Route path={ordersPath} element={<Orders/>}/>
                            </Route>
                            {/* orders list with PAGE */}
                            <Route path={`${ordersPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${ordersPath}:page/`} element={<Orders/>}/>
                            </Route>
                            {/* order details */}
                            <Route path={`${orderPath}:page/:id/`} element={<PrivateRoute/>}>
                                <Route path={`${orderPath}:page/:id/`} element={<Order/>}/>
                            </Route>
                            {/* order details for CUSTOMER */}
                            <Route path={`${orderPath}:page/:id/:customer/`} element={<PrivateRoute/>}>
                                <Route path={`${orderPath}:page/:id/:customer/`} element={<Order/>}/>
                            </Route>

                            <Route path={`${pdfPath}:id/`} element={<Pdf/>}/>

                            {/* customers list */}
                            <Route path={customersPath} element={<PrivateRoute/>}>
                                <Route path={customersPath} element={<Customers/>}/>
                            </Route>
                            {/* customers list with PAGE */}
                            <Route path={`${customersPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${customersPath}:page/`} element={<Customers/>}/>
                            </Route>
                            {/* customer details */}
                            <Route path={`${customerPath}:page/:id/`} element={<PrivateRoute/>}>
                                <Route path={`${customerPath}:page/:id/`} element={<Customer/>}/>
                            </Route>

                            {/* single worksheet */}
                            <Route path={`${worksheetPath}:id/`} element={<PrivateRoute/>}>
                                <Route path={`${worksheetPath}:id/`} element={<Worksheet/>}/>
                            </Route>
                            <Route path={`${worksheetPath}:customerId/:id/`} element={<PrivateRoute/>}>
                                <Route path={`${worksheetPath}:customerId/:id/`} element={<Worksheet/>}/>
                            </Route>

                            {/* worksheets list */}
                            <Route path={worksheetsPath} element={<PrivateRoute/>}>
                                <Route path={worksheetsPath} element={<Worksheets/>}/>
                            </Route>
                            {/* worksheets list with PAGE */}
                            <Route path={`${worksheetsPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${worksheetsPath}:page/`} element={<Worksheets/>}/>
                            </Route>
                            {/* worksheets list for single CUSTOMER */}
                            <Route path={`${worksheetsPath}:page/:customerId/:worksheetPage/`}
                                   element={<PrivateRoute/>}>
                                <Route path={`${worksheetsPath}:page/:customerId/:worksheetPage/`}
                                       element={<WorksheetsPerCustomer/>}/>
                            </Route>
                            {/* worksheet details for single CUSTOMER */}
                            <Route path={`${worksheetPath}:page/:customerId/:worksheetPage/:worksheetId`}
                                   element={<PrivateRoute/>}>
                                <Route path={`${worksheetPath}:page/:customerId/:worksheetPage/:worksheetId`}
                                       element={<Worksheet/>}/>
                            </Route>

                            {/* invoices list */}
                            <Route path={invoicesPath} element={<PrivateRoute/>}>
                                <Route path={invoicesPath} element={<Invoices/>}/>
                            </Route>
                            {/* invoices list with PAGE */}
                            <Route path={`${invoicesPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${invoicesPath}:page/`} element={<Invoices/>}/>
                            </Route>
                            {/* invoice details - just invoice */}
                            <Route path={`${invoicePath}:page/:invoiceId`} element={<PrivateRoute/>}>
                                <Route path={`${invoicePath}:page/:invoiceId`} element={<Invoice/>}/>
                            </Route>
                            {/* invoice details - temp in case we need more navigation freedom */}
                            <Route path={`${invoicePath}:page/:customerId/:invoicePage/:invoiceId`}
                                   element={<PrivateRoute/>}>
                                <Route path={`${invoicePath}:page/:customerId/:invoicePage/:invoiceId`}
                                       element={<Invoice/>}/>
                            </Route>

                            <Route path={`${profilePath}/`} element={<PrivateRoute/>}>
                                <Route path={`${profilePath}/`} element={<Profile/>}/>
                            </Route>
                            <Route path={`${settingsPath}/`} element={<PrivateRoute/>}>
                                <Route path={`${settingsPath}/`} element={<Settings/>}/>
                            </Route>

                            <Route path={loginPath} element={<Login/>}/>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>

                        <Notification/>
                        <Dialog/>
                        <Modal/>
                    </AuthWrapper>
                </ContextWrapper>
            </Suspense>
        </BrowserRouter>
    )
}

export default App
