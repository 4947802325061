import {endpoint} from "../../variables"
import {Axios} from "../../Axios"
import {orderPath} from "../../components/Order/Order"
import {handleError} from "../../shared/helpers"

interface GetOrderIF {
    orderNumber: number
    orderCallback: Function
    itemsCallback: Function
    path?: string
    customer?: number
    loaderCallback?: Function
}

const itemPath = "/items/"

export const getOrder = ({orderNumber, orderCallback, itemsCallback, path, customer, loaderCallback}: GetOrderIF) => {
    if (!orderNumber) {
        loaderCallback && loaderCallback(false)
        orderCallback({
            id: 0,
            customerId: customer
        })

        return
    }

    Axios.get(`${endpoint}${orderPath}${orderNumber}/`)
        .then(({data}) => {
            // getting order info
            const {resource} = data

            if (!resource) {
                handleError(`No success on getting items`)

                return
            }

            orderCallback({
                ...resource,
                id: orderNumber
            })
        })
        .then(() => {
            Axios.get(`${endpoint}${itemPath}${orderNumber}/`)
                .then(({data}) => {
                    // getting order ITEMS info
                    const {resource} = data

                    if (!resource) {
                        handleError(`No success on getting items`)

                        return
                    }

                    itemsCallback(resource)
                })
                .catch(error => handleError(error))
        })
        .finally(() => loaderCallback && loaderCallback(false))
        .catch(error => handleError(error))
}
