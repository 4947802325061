import React, {useEffect, useState} from "react"
import {formatPrice} from "../../shared/_order_helper"
import CreatableSelect from "react-select/creatable"
import Actions from "../_shared/Actions/Actions"

interface ItemIF {
    item: any
    index: number
    updateItem: Function
    removeItem: Function
    products: Array<any>
}

const Item = ({item, index, updateItem, removeItem, products}: ItemIF) => {
    const quantity = item.quantity || 0
    const totalPrice = formatPrice(parseFloat(item.price) * quantity)
    const totalDiscountPrice = formatPrice(parseFloat(item.discountPrice) * quantity)
    const itemDiscount = parseFloat(totalPrice) ? (100 - parseFloat(totalDiscountPrice) * 120 / parseFloat(totalPrice)).toFixed(2) : ""
    const [showComment, setShowComment] = useState(!!item.comment)
    const value = products.filter(product => product.label === item.name)
    const placeholder = item.name && !value.length ? {placeholder: item.name} : {}
    const handlePriceBlur = (event: any) => {
        event.currentTarget.value = formatPrice(event.currentTarget.value)
    }

    useEffect(() => {
        setShowComment(!!item.comment)
    }, [item])

    return (
        <>
            <tr className="row" key={`${item.id}${index}`} data-item={item.id}>
                <td className="id">
                    <input name="code"
                           value={item.code}
                           onChange={event => updateItem(index, event)}/>
                </td>
                <td className="name">
                    <CreatableSelect
                        className="select"
                        classNamePrefix="select"
                        defaultValue={value}
                        name="name-select"
                        options={products}
                        isValidNewOption={() => false}
                        onChange={product => updateItem(index, product)}
                        {...placeholder}
                    />

                    <input name="name"
                           type="hidden"
                           defaultValue={item.name}
                           onChange={event => updateItem(index, event)}/>
                </td>
                <td className="quantity">
                    <input name="quantity"
                           type="number"
                           defaultValue={quantity}
                           onChange={event => updateItem(index, event)}/>
                </td>
                <td className="price">
                    <input name="price"
                           type="number"
                           step="0.01"
                           onBlur={(event) => handlePriceBlur(event)}
                           value={item.price}
                           onChange={event => updateItem(index, event)}/>
                </td>
                <td className="total-price">
                    <div className="fake-input">
                        {totalPrice}
                    </div>
                </td>
                <td className="discount-price">
                    <input name="discount-price"
                           type="number"
                           step="0.01"
                           onBlur={(event) => handlePriceBlur(event)}
                           value={item.discountPrice}
                           onChange={event => updateItem(index, event)}/>
                </td>
                <td className="total-discount">
                    <div className="fake-input">
                        {totalDiscountPrice}
                    </div>
                </td>
                <td className="discount">
                    <div className="fake-input">
                        {itemDiscount}
                    </div>
                </td>
                <td className="actions">
                    <Actions id={item.id}
                             commentCallback={() => setShowComment(!showComment)}
                             deleteCallback={() => removeItem(item.id)}
                    />
                </td>
            </tr>

            {showComment &&
                <tr className="comment">
                    <td className="comment" colSpan={100}>
                        <div className="label">Comment</div>
                        <input name="comment"
                               defaultValue={item.comment}
                               onChange={event => updateItem(index, event)}/>
                    </td>
                </tr>
            }
        </>
    )
}

export default Item
