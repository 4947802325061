import React, {useCallback, useContext, useEffect, useState} from "react"
import {endpoint} from "../../variables"
import {formatDate, handleError, isEmpty} from "../../shared/helpers"
import {useNavigate, useLocation} from "react-router-dom"
import {Axios} from "../../Axios"
import {orderPath} from "../Order/Order"
import {Context} from "../../contexts/Context"
import {getUrlParams} from "../../utils/_helpers"
import {AuthContext} from "../../contexts/Auth"
import DefaultList from "../_shared/DefaultList/DefaultList"
import NoEntries from "../_shared/NoEntries/NoEntries"
import Actions from "../_shared/Actions/Actions"
import {DataResponseIF, MetaIF, OrderIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"
import {useTranslation} from "react-i18next"

export const ordersPath = "/orders/"

interface OrdersIF {
    customer?: number
    compact?: boolean
}

const Orders = ({customer, compact}: OrdersIF) => {
    const [orders, setOrders] = useState<Array<OrderIF> | null>(null)
    const [unfilteredData, setUnfilteredData] = useState<DataResponseIF | null>(null)
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const page = parseInt(path["pageNumber"]) || 1
    const {confirmation} = useContext(Context)
    const [meta, setMeta] = useState<MetaIF | null>(null)
    const {rootState} = useContext(AuthContext)
    const {isAuth} = rootState
    const [loader, setLoader] = useState(true)
    const {t} = useTranslation()

    const fetchOrders = useCallback(() => {
        Axios.get(`${endpoint}${ordersPath}${customer ? "1" : page}/${customer ? `${customer}/` : ""}`)
            .then(({data}) => {
                setLoader(false)
                setMeta(data.meta)
                setOrders(data.resource)
                setUnfilteredData(data)
            })
            .catch(error => handleError(error))
    }, [customer, page])

    useEffect(() => {
        if (!isAuth) {
            return
        }

        fetchOrders()
    }, [isAuth, fetchOrders])

    const customerNickname = (nickname: string) => {
        if (nickname) {
            return ` (${nickname})`
        }
    }

    const handleViewClick = (id: number) => {
        navigate(`${orderPath}${page}/${id}/`)
    }

    const handleDelete = (event: Event, id: number, list: Array<OrderIF>) => {
        handleDeleteItem({
                event: event as unknown as Event,
                confirmation,
                id,
                list,
                setList: setOrders,
                navigate,
                resource: "order"
            }
        )
    }

    const ordersInTable = () => {
        if (!orders) {
            return null
        }

        let orderList = []

        orderList.push(Object.values(compact ? orders.slice(0, 5) : orders).map(({
                                                      timestamp,
                                                      items,
                                                      id,
                                                      name,
                                                      nickname,
                                                      offer
                                                  }: OrderIF, index) => {
            const orderDate = formatDate(timestamp)
            const className = [
                "items",
                ...(!items || !offer ? ["error"] : [])
            ].join(" ")

            return (
                <tr className="row" key={index}>
                    <td onClick={() => handleViewClick(id)} className="id">{id}</td>
                    {!customer && <td onClick={() => handleViewClick(id)}
                                      className="name">{name}{customerNickname(nickname)}</td>}
                    <td onClick={() => handleViewClick(id)} className="items">{orderDate}</td>
                    <td onClick={() => handleViewClick(id)}
                        className={className}>{items}{t("orders.pc")} / {offer}€
                    </td>
                    {!compact && <td className="actions">
                        <Actions compact={compact}
                                 id={id}
                                 viewCallback={handleViewClick}
                                 deleteCallback={handleDelete}
                        />
                    </td>}
                </tr>
        )
        }))

        return orderList
    }

    const showList = () => {
        if (isEmpty(orders)) {
            return <NoEntries/>
        }

        return (
            <table className={`general-table orders-table${compact ? " compact" : ""}`}>
                <thead>
                <tr>
                    <th className="id">#</th>
                    {!customer && <th className="name">{t("orders.form.customer")}</th>}
                    <th className="items">{t("orders.form.date")}</th>
                    <th className="items">{t("orders.form.items")} / {t("orders.form.price")}</th>
                    {!compact && <th className="actions"/>}
                </tr>
                </thead>
                <tbody>
                {ordersInTable()}
                </tbody>
            </table>
        )
    }

    const handleCallback = (data: DataResponseIF) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        setOrders(data ? data.resource as Array<OrderIF> : unfilteredData.resource as Array<OrderIF>)
    }

    return (
        <DefaultList list="orders"
                     loader={loader}
                     setLoader={setLoader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     id={customer}
                     compact={compact}
                     callback={(data: DataResponseIF) => handleCallback(data)}
        />
    )
}

export default Orders
