import React, {createElement, useContext, useEffect, useRef, useState} from "react"
import {endpoint} from "../../variables"
import {Axios} from "../../Axios"
import Button from "../_shared/Form/Button/Button"
import {AuthContext} from "../../contexts/Auth"
import {useTranslation} from "react-i18next"
import {handleError} from "../../shared/helpers"
import {Context} from "../../contexts/Context"
import Loader from "../_shared/Loader/Loader"
import {getSettings} from "../../utils/Getters/getSettings"
import {SettingsIF} from "../../../types"
import "./Settings.sass"

export const settingsPath = "/settings/"

const Settings = () => {
    const {t} = useTranslation()
    const authContext = useContext(AuthContext)
    const {notification} = useContext(Context)
    const {rootState: {isAuth}} = authContext
    const refs = useRef<{ [key: string]: HTMLInputElement | null }>({})
    const [settings, setSettings] = useState<SettingsIF | null>(null)
    const [loader, setLoader] = useState(true)
    const [logoFile, setLogoFile] = useState<File | null>(null)
    const logoRef = useRef<HTMLInputElement | null>(null)

    const setRef = (key: string) => (element: HTMLInputElement | null) => {
        refs.current[key] = element
    }

    useEffect(() => {
        if (!isAuth) {
            return
        }

        setLoader(true)

        getSettings({
            callback: (data: SettingsIF) => {
                setSettings(data)
                setLoader(false)
            }
        })
    }, [])

    const doSave = () => {
        const formData = new FormData()

        Object.keys(refs.current).forEach(item => {
            formData.append(item, refs.current[item]?.value || "")
        })

        if (logoFile) {
            formData.append("logo", logoFile)
        }

        Axios.put(`${endpoint}${settingsPath}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(({data}) => {
                const {success, resource} = data

                if (!success) {
                    handleError(`Molbert gone, brush gone... `)

                    return
                }

                setSettings({
                    ...settings,
                    logo: resource
                })

                notification?.update({
                    show: true
                })
            })
            .catch(error => handleError(error))
    }

    const renderField = (input: string, textarea = false) => {
        const value = settings?.[input]
        const tag = textarea ? "textarea" : "input"

        return (
            <div className="field">
                <label htmlFor={input}>{t(`settings.form.${input}`)}</label>
                {createElement(tag, {
                    name: input,
                    id: input,
                    ref: setRef(input),
                    defaultValue: value
                })}
            </div>
        )
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setLogoFile(event.target.files[0])
        }
    }

    const deleteImage = (event: Event) => {
        event.preventDefault()

        Axios.delete(`${endpoint}${settingsPath}`)
            .then(({data}) => {
                const {success, message} = data

                if (!success) {
                    handleError(message)

                    return
                }

                setSettings({
                    ...settings,
                    logo: ""
                })
            })
            .catch(error => handleError(error))
    }

    const showLogo = () => {
        return (
            <>
                <img src={`${endpoint}/uploads/${settings?.logo}`} alt=""/>
                <Button text={t("button.delete")} additionalClassName="small tertiary" onClick={(event: Event) => deleteImage(event)}/>
            </>
        )
    }

    const showLogoUpload = () => {
        return (
            <>
                {logoFile &&
                    createElement("input", {
                        defaultValue: logoFile.name,
                        disabled: true
                    })
                }

                <Button additionalClassName="primary large"
                        text={t("button.upload")}
                        onClick={(event: Event) => {
                            event.preventDefault()

                            logoRef.current?.click()
                        }}
                ></Button>
                <input type="file"
                       id="logoInput"
                       accept="image/*"
                       onChange={handleFileChange}
                       className="logo-upload"
                       ref={logoRef}
                />
            </>
        )
    }

    return (
        loader ?
            <Loader/>
            :
            <section className="component customer">
                <header>
                    <h1>{t("settings.plural")}</h1>

                    <div className="actions">
                        <Button additionalClassName="primary large" text={t("button.save")}
                                onClick={() => doSave()}></Button>
                    </div>
                </header>

                <form className="general-form settings">
                    <div className="splitter">
                        {renderField("vat")}
                        {renderField("lastInvoiceId")}
                    </div>

                    <div className="field">
                        <h3>{t("settings.pdfSettings")}</h3>
                    </div>

                    <div className="splitter">
                        <div className="field logo">
                            <label htmlFor="logoInput">{t("settings.form.logo")}</label>

                            {settings?.logo ? showLogo() : showLogoUpload()}
                        </div>

                        {renderField("pdfDisclaimer", true)}
                    </div>


                    <div className="splitter by-three">
                        {renderField("companyName")}
                        {renderField("companyEmail")}
                        {renderField("companyPhone")}
                    </div>
                    <div className="splitter by-three">
                        {renderField("companyAddress1")}
                        {renderField("companyWebsite")}
                        {renderField("companyRegNr")}
                    </div>
                    <div className="splitter">
                        {renderField("companyAddress2")}
                        {renderField("companyVatNr")}
                    </div>
                    <div className="splitter">
                        {renderField("companyBankAccount")}
                        {renderField("companyBankAccount2")}
                    </div>
                </form>
            </section>
    )
}

export default Settings
